import * as CryptoJS from 'crypto-js'

const CryptService = (function() {
  var me = {
    key: 'crypt_key_w6VXatA23n',
  }

  var _sep = ''

  me.setSym = function(sym) {
    _sep = sym
  }

  me.crypt = function(data) {
    if (typeof data === 'object') {
      data = JSON.stringify(data)
    }
    return CryptoJS.AES.encrypt(data, me.key)
      .toString()
      .split('/')
      .join(_sep)
  }

  me.decrypt = function(id, json = false) {
    const dec = CryptoJS.AES.decrypt(id.split(_sep).join('/'), me.key).toString(CryptoJS.enc.Utf8)
    if (json) {
      return JSON.parse(dec)
    }
    return dec
  }

  me.sha1 = function(v) {
    return CryptoJS.SHA1(v).toString()
  }

  return me
})()

export default CryptService
